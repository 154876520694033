import './App.css';
import { Suspense, lazy, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Context from './Context';
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";
import axios from 'axios';
import Cookies from './Components/Cookies';
import TagManager from 'react-gtm-module';
import SEOHelper from './Components/SEOHelper';
import SEOHelperC from './Components/SEOHelperC';
import { setDefaultMetas } from "./Utils/SEOHelper";

const Home = lazy(() => import('./Pages/Home'));
const Empresa = lazy(() => import('./Pages/Empresa'));
const Blog = lazy(() => import('./Pages/Blog'));
const Post = lazy(() => import('./Pages/Post'));
const Segmento = lazy(() => import('./Pages/Segmento'));
const Produto = lazy(() => import('./Pages/Produto'));
const Contato = lazy(() => import('./Pages/Contato'));
const Texto = lazy(() => import('./Pages/Texto'));
const EstudosClinicos = lazy(() => import('./Pages/EstudosClinicos'));

function App() {
  // localStorage.setItem("apiUrl", "https://gelcopep.com/sistema/api/");
  // localStorage.setItem("apiUrl", "http://localhost:8080/api/");
  localStorage.setItem("apiUrl", "/sistema/api/");
  const [resumo, setResumo] = useState({});
  const [modal, setModal] = useState(false);
  const [isRep, setIsRep] = useState(false);
  const [activePage, setActivePage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [language, setCLanguage] = useState("");
  const value = { resumo, language, setCLanguage, setResumo, modal, setModal, activePage, setActivePage, menuOpen, setMenuOpen, cart, setCart, isRep, setIsRep };

  useEffect(() => {
    axios.get(localStorage.getItem("apiUrl") + "configuracoes").then(response => {
      if (response.data && response.data.success) {
        if (response.data.success.seo && response.data.success.seo[0]) {
          setDefaultMetas(response.data.success.seo[0])
        }
        setResumo(response.data.success);
        if (response.data.success.configuracoes.tag_manager) {
          if (response.data.success.configuracoes.tag_manager.length > 5) {
            const tagManagerArgs = {
              gtmId: response.data.success.configuracoes.tag_manager
            }
            let checkCookies = setInterval(() => {
              let accept = localStorage.getItem("acceptCookiesDesnecessarios");
              if (accept) {
                TagManager.initialize(tagManagerArgs)
                clearInterval(checkCookies);
              }
            }, 3000);
          }
        }
      }
    });

    let carrinho = JSON.parse(localStorage.getItem("carrinho"));
    if (!carrinho) {
      carrinho = []
    }
    setCart(carrinho);

    let id = localStorage.getItem("id");
    let codigo = localStorage.getItem("codigo");
    if (id && codigo) {
      axios.get(localStorage.getItem("apiUrl") + "check?id=" + id + "&codigo=" + codigo).then(response => {
        if (response.data.status !== 200) {
          localStorage.removeItem("id");
          localStorage.removeItem("codigo");
        } else {
          if (response.data.success.is_rep === 1) {
            setIsRep(true);
          }
        }
      });
    }
  }, []);

  const createCookie = (name, value, days, domain) => {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; domain=" + domain + "; path=/";
  }

  const eraseCookie = (name, domain) => {
    createCookie(name, "", -1, domain);
  }

  const getCookie = (cookieName) => {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
      let [key, value] = el.split('=');
      cookie[key.trim()] = value;
    })
    return cookie[cookieName];
  }

  useEffect(() => {
    let applied = false;
    const currentScripts = document.querySelectorAll("script");
    currentScripts.forEach(tag => {
      if (tag.src.includes("translate")) {
        applied = true;
      }
    });

    if (!applied) {
      const externalResource = document.createElement("script");
      externalResource.type = "text/javascript";
      externalResource.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";

      const translateScript = document.createElement("script");
      translateScript.type = "text/javascript";
      translateScript.innerHTML = "function googleTranslateElementInit() {new google.translate.TranslateElement({ pageLanguage: 'pt', layout: google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element');}";

      document.body.appendChild(externalResource);
      document.body.appendChild(translateScript);
    }
  }, []);

  const setLanguage = (lang = "pt") => {
    eraseCookie("googtrans", `${window.location.hostname}`);
    eraseCookie("googtrans", `.${window.location.hostname}`);
    eraseCookie("googtrans", `.${window.location.hostname.replace("www.", "")}`);
    eraseCookie("googtrans", `${window.location.hostname.replace("www.", "")}`);
    eraseCookie("googtrans", "");
    createCookie("googtrans", `/pt/${lang}`, 1, `.${window.location.hostname}`);
    document.location.href = `/${lang !== "pt" ? lang : ""}`;
  }

  useEffect(() => {
    if (getCookie("googtrans") && getCookie("googtrans") === "/pt/en") {
      setCLanguage("_en");
    } else if (getCookie("googtrans") && getCookie("googtrans") === "/pt/es") {
      setCLanguage("_es");
    } else {
      setCLanguage("");
    }
  }, []);

  return (
    <div className="App">
      <div id="google_translate_element"></div>
      <HelmetProvider>
        <Context.Provider value={value}>
          <div id="modal" className={modal ? "active" : ""}>
            {modal && <div className="content">
              <h3>{modal.titulo}</h3>
              <p dangerouslySetInnerHTML={{ __html: modal.texto }}></p>
              <button type="button" onClick={() => { if (modal.onExit) { modal.onExit() } setModal(false) }}>{modal.botao}</button>
            </div>}
          </div>
          <h1 id="mainTitle" className='hidden'>GelcoPEP</h1>
          {resumo.textos &&
            <Router basename={`/${language.replace("_", "")}`}>
              <Header setLanguage={setLanguage} getCookie={getCookie} />
              <ScrollToTop setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
              {window.defaultMetas && <SEOHelper />}
              <SEOHelperC />
              <Suspense fallback={<div className="suspense"></div>}>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/empresa">
                    <Empresa />
                  </Route>
                  <Route exact path="/segmento/:slug">
                    <Segmento />
                  </Route>
                  <Route exact path="/produto/:slug">
                    <Produto />
                  </Route>
                  <Route exact path="/fale-conosco">
                    <Contato />
                  </Route>
                  <Route exact path="/trabalhe-:trabalhe">
                    <Contato />
                  </Route>
                  <Route exact path="/estudos-clinicos">
                    <EstudosClinicos />
                  </Route>
                  <Route exact path="/blog">
                    <Blog />
                  </Route>
                  <Route exact path="/blog/:slug">
                    <Post />
                  </Route>
                  {resumo.textos &&
                    <Route exact path="/termos-de-uso">
                      <Texto titulo={"Termos de uso"} texto={resumo.textos[`termos_de_uso${language}`] ? resumo.textos[`termos_de_uso${language}`] : resumo.textos[`termos_de_uso`]} />
                    </Route>
                  }
                  {resumo.textos &&
                    <Route exact path="/politica-de-privacidade">
                      <Texto titulo={"Política de privacidade"} texto={resumo.textos[`politica_de_privacidade${language}`] ? resumo.textos[`politica_de_privacidade${language}`] : resumo.textos[`politica_de_privacidade`]} />
                    </Route>
                  }
                  {resumo.textos &&
                    <Route exact path="/tratamento-de-dados">
                      <Texto titulo={"Tratamento de dados"} texto={resumo.textos[`tratamento_de_dados${language}`] ? resumo.textos[`tratamento_de_dados${language}`] : resumo.textos[`tratamento_de_dados`]} />
                    </Route>
                  }
                  {resumo.textos &&
                    <Route exact path="/codigo-de-conduta">
                      <Texto titulo={"Código de conduta"} texto={resumo.textos[`codigo_de_conduta${language}`] ? resumo.textos[`codigo_de_conduta${language}`] : resumo.textos[`codigo_de_conduta`]} />
                    </Route>
                  }
                </Switch>
              </Suspense>
              <Cookies />
              <Footer />
            </Router>
          }
        </Context.Provider>
      </HelmetProvider>
      {
        resumo.configuracoes && <div itemScope itemType="https://schema.org/Organization" className="schema">
          <span itemProp="name">Gelco Gelatinas do Brasil</span>
          <span itemProp="alternateName">Gelco PEP</span>
          <span itemProp="url">https://www.gelcopep.com/</span>
          <span itemProp="logo">https://www.gelcopep.com/sistema/api/imagens/medium/logo.svg</span>
          <span itemProp="sameAs">https://www.facebook.com/gelcopep</span>
          <span itemProp="sameAs">https://www.instagram.com/gelcopepoficial/</span>
        </div>
      }
    </div >
  );
}

export default App;
